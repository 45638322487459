import { useStore } from 'public/src/pages/checkout/hooks/index.js'
import { computed, toRefs } from 'vue'
import { template } from '@shein/common-function'

export const useDiscount = (
  {
    initialPayment,
    language,
    isUseInitialPayment = false
  } = {}
) => {
  const store = useStore()

  const allCheckoutData = computed(() => {
    return store?.state?.checkout
  })

  const {
    checkout,
    status,
    price
  } = toRefs(allCheckoutData.value)

  // 支付方式
  const paymentMethods = computed(() => {
    return checkout.value?.results?.paymentMethods || []
  })

  // 当前支付方式
  const currentPayment = computed(() => {
    return paymentMethods.value.find(
      item => item.code === status.value?.cardPay
    )
  })

  const payment = computed(() => {
    return isUseInitialPayment ? initialPayment : currentPayment.value
  })

  const onlinePayDiscountInfoValue = computed(() => {
    return (
      price.value?.onlinePayDiscountInfo?.onlinePayDiscountValue?.[
        payment.value?.code
      ] || null
    )
  })

  // 随机立减abt
  const randomDiscountAbt = computed(() => {
    return (
      checkout.value?.abtInfo?.PaymentRandomDiscount?.param
        ?.PaymentRandomShow == 'Show'
    )
  })

  const isShowRandomDiscountDes = computed(() => {
    return (
      onlinePayDiscountInfoValue.value &&
      onlinePayDiscountInfoValue.value?.discountType == 2 &&
      randomDiscountAbt.value &&
      payment.value?.enabled
    )
  })

  // 当前支付方式是否展示在线支付随机立减优惠
  const isShowOnlineRandomDiscount = computed(() => {
    const onlinePayDiscountValue = onlinePayDiscountInfoValue.value || {}
    return (
      onlinePayDiscountValue.discountType == 2 &&
      +onlinePayDiscountValue?.random_max?.amount != 0 &&
      randomDiscountAbt.value
    )
  })

  // 有门槛/最高折扣金额展示/随机立减（有门槛或0门槛）
  const isShowOnlinePayDiscount = computed(() => {
    const onlinePayDiscountValue = onlinePayDiscountInfoValue.value || {}
    return (
      payment.value?.enabled &&
      !!Object.keys(onlinePayDiscountValue)?.length &&
      ((onlinePayDiscountValue.discountType != 2 &&
        onlinePayDiscountValue?.min &&
        onlinePayDiscountValue?.min?.amount != 0) ||
        (onlinePayDiscountValue.discountType == 1 &&
          onlinePayDiscountValue?.limit_value &&
          onlinePayDiscountValue?.limit_value?.amount != 0) ||
        isShowOnlineRandomDiscount.value)
    )
  })

  // abt 是否展示 token 支付
  const isShowTokenPaymentByAbt = computed(() => {
    return true  // 11.04已推全
  })

  const isShowTokenPayment = computed(() => {
    // 后端字段是否展示 token 支付
    const isShowTokenPaymentByBackEnd =
      payment.value?.code === 'routepay-card' &&
      payment.value?.shieldAddCard === 0 // 0代表b不屏蔽，则走abt的判断逻辑
    // 若token数据为空，则判断不符合token前置的场景，则无新增逻辑
    return (
      isShowTokenPaymentByBackEnd &&
      isShowTokenPaymentByAbt.value &&
      (payment.value?.card_token_list || [])?.length > 0
    )
  })

  const isShowCardBinDiscountABT = computed(() => {
    const { BinDiscountdiscount = {} } = checkout.value?.abtInfo || {}
    return BinDiscountdiscount?.param?.BinDiscountShow === 'Show'
  })

  // 卡bin随机立减展示
  const isShowBinRandomDiscountABT = computed(() => {
    const { BinRandomDiscount = {} } = checkout.value?.abtInfo || {}
    return BinRandomDiscount?.param?.BinRandomShow === 'Show'
  })

  // 卡bin优惠信息
  const cardBinDiscountDesc = computed(() => {
    // 当命中多个卡bin优惠，不区分命中的类型
    if (checkout.value?.results?.bin_discount_list?.length > 1) {
      const lengthObj = {
        2: language?.SHEIN_KEY_PWA_26144,
        3: language?.SHEIN_KEY_PWA_26145
      }

      return template(
        ...checkout.value?.results?.bin_discount_list?.map(
          item => item?.bank_name
        ),
        lengthObj[checkout.value?.results?.bin_discount_list?.length]
      )
    }

    const {
      bank_name = '',
      discount_amount = {},
      discount_percentage = 0,
      discount_type = 0,
      threshold_amount = {},
      max_discount_amount = {},
      max_random_amount = {},
      min_random_amount = {}
    } = checkout.value?.results?.bin_discount_list?.[0] ||
    checkout.value?.results?.bin_discount_info ||
    {}
    if (discount_type === 1) {
      return template(
        discount_amount?.amountWithSymbol,
        threshold_amount?.amountWithSymbol,
        bank_name || '',
        language?.SHEIN_KEY_PWA_24447
      )
    }
    if (discount_type === 2) {
      const maxDiscountAmount = max_discount_amount?.amount
      const thresholdAmount = threshold_amount?.amount
      // 折扣--无门槛
      if (thresholdAmount == 0) {
        return template(
          maxDiscountAmount > 0
            ? max_discount_amount.amountWithSymbol
            : discount_percentage || '',
          bank_name || '',
          maxDiscountAmount > 0
            ? language?.SHEIN_KEY_PWA_24525
            : language?.SHEIN_KEY_PWA_24526
        )
      }
      return template(
        maxDiscountAmount > 0
          ? max_discount_amount.amountWithSymbol
          : discount_percentage || '',
        bank_name || '',
        threshold_amount?.amountWithSymbol,
        maxDiscountAmount > 0
          ? language?.SHEIN_KEY_PWA_24453
          : language?.SHEIN_KEY_PWA_24448
      )
    }
    // 随机立减
    if (discount_type == 3 && isShowBinRandomDiscountABT.value) {
      const thresholdAmount = threshold_amount?.amount
      // 折扣--无门槛
      if (thresholdAmount == 0) {
        return template(
          min_random_amount?.amountWithSymbol,
          max_random_amount?.amountWithSymbol,
          bank_name || '',
          bank_name
            ? language?.SHEIN_KEY_PWA_28692
            : language?.SHEIN_KEY_PWA_24866
        )
      }
      return template(
        min_random_amount?.amountWithSymbol,
        max_random_amount?.amountWithSymbol,
        threshold_amount?.amountWithSymbol,
        bank_name || '',
        bank_name
          ? language?.SHEIN_KEY_PWA_28691
          : language?.SHEIN_KEY_PWA_24937
      )
    }
    return ''
  })

  const isShowCardBinDiscount = computed(() => {
    if (isShowTokenPayment.value) return false // token 的时候不展示 卡 bin 优惠

    if (checkout.value?.results?.bin_discount_list?.length > 1) {
      let is_discount_type = false
      let is_match_payments = checkout.value?.results?.bin_discount_list
        .map(binDiscountItem => {
          const { discount_type = 0, match_payments = [] } =
            binDiscountItem || {}
          const matchPayment = (match_payments || []).some(
            v => v === payment.value?.code
          )
          if (discount_type != 0) {
            is_discount_type = true
          }
          return matchPayment
        })
        .some(isMatchPayment => isMatchPayment)
      return (
        payment.value?.enabled &&
        is_discount_type &&
        is_match_payments &&
        isShowCardBinDiscountABT.value
      )
    }

    const { discount_type = 0, match_payments = [] } =
      checkout.value?.results?.bin_discount_list?.[0] ||
      checkout.value?.results?.bin_discount_info ||
      {}
    const matchPayment = (match_payments || []).some(
      v => v === payment.value?.code
    )
    return (
      payment.value?.enabled &&
      discount_type &&
      matchPayment &&
      isShowCardBinDiscountABT.value &&
      // 如果同时存在在线支付优惠随机立减和卡bin随机立减，只展示在线支付优惠随机立减
      (discount_type == 3
        ? !isShowOnlineRandomDiscount.value && !!cardBinDiscountDesc.value
        : true)
    )
  })

  const isHadDisCount = () => {
    return isShowRandomDiscountDes.value || isShowOnlinePayDiscount.value || isShowCardBinDiscount.value
  }

  return {
    onlinePayDiscountInfoValue,
    cardBinDiscountDesc,
    isShowRandomDiscountDes,
    isShowOnlinePayDiscount,
    isShowCardBinDiscount,
    isHadDisCount,
    randomDiscountAbt,
    isShowTokenPayment
  }
}
