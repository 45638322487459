import { template } from '@shein/common-function'
import { computed } from 'vue'
import { useStore } from 'public/src/pages/checkout/hooks/index.js'

export const useCouponsDiscount = language => {
  const percentFormat = num => {
    const newNum = (num * 100) / 100
    const percentNum = newNum + '%'
    return percentNum
  }

  // satisfied_range 当前用户满足的最高档次：0：所有档次都不满足 1：满足第一档 2：满足第二档，3：满足第了档，4：满足第4档，以此类推
  const getCurrentCouponRule = supportBinData => {
    // 多档券
    if (
      supportBinData?.other_coupon_rule?.length > 1 &&
      supportBinData?.satisfied_range > 0
    ) {
      return supportBinData?.other_coupon_rule[
        supportBinData?.satisfied_range - 1
      ]
    }
    return supportBinData?.other_coupon_rule?.[0]
  }

  const getDiscount = (couponTypeId, currentCouponRule) => {
    if (couponTypeId == '1') {
      // 减元
      return template(
        currentCouponRule?.price?.priceSymbol || '',
        language.SHEIN_KEY_PWA_15816
      )
    }

    if ([2, 5].includes(+couponTypeId)) {
      // 折扣
      return template(
        percentFormat(currentCouponRule?.price?.price || ''),
        language.SHEIN_KEY_PWA_15816
      )
    }
    return ''
  }

  return {
    getDiscount,
    getCurrentCouponRule
  }
}

export const useIsShowPayBinCoupons = ({ pay }) => {
  const store = useStore()

  const isShowTokenPaymentByAbt = computed(() => {
    return true  // 11.04已推全
  })

  const isShowTokenPayment = computed(() => {
    // 后端字段是否展示 token 支付
    const isShowTokenPaymentByBackEnd =
      pay?.code === 'routepay-card' && pay?.shieldAddCard === 0 // 0代表b不屏蔽，则走abt的判断逻辑
    // 若token数据为空，则判断不符合token前置的场景，则无新增逻辑
    return (
      isShowTokenPaymentByBackEnd &&
      isShowTokenPaymentByAbt.value &&
      (pay?.card_token_list || [])?.length > 0
    )
  })

  const handlerSelectBin = computed(() => {
    return store?.state?.checkout?.handlerSelectBin
  })

  const defaultSelectTokenPayCardBin = computed(() => {
    return store?.state?.checkout?.defaultSelectTokenPay?.card_bin
  })

  const cardBin = computed(() => {
    return handlerSelectBin.value || defaultSelectTokenPayCardBin.value || ''
  })

  const usableCouponList = computed(() => {
    return store?.state?.checkout?.usableCouponList
  })

  const coupon = computed(() => {
    return store?.state?.checkout?.coupon
  })

  const supportBinData = computed(() => {
    if (isShowTokenPayment.value) {
      // token支付情况下，展示券逻辑
      const allCanUseUsableCouponList =
        usableCouponList.value?.filter(item =>
          item?.card?.card_codes?.includes(cardBin.value)
        ) || []

      const nowUseCoupon = allCanUseUsableCouponList?.find(item =>
        coupon.value?.applyCoupons?.includes(item?.coupon)
      )

      // 已若卡bin优惠券券已被使用（计算价格使用了券码），则正常下发展示使用成功的卡bin优惠券信息
      if (nowUseCoupon) {
        return nowUseCoupon
      }

      // 若存在交集的券数量＞1，则优先取接口返回的第一个券
      return allCanUseUsableCouponList?.[0]
    } else {
      const nowUseCoupon = usableCouponList.value?.find(item => {
        return (
          coupon.value?.applyCoupons?.includes(item?.coupon) &&
          item?.card?.card_name
        )
      })
      // 已若卡bin优惠券券已被使用（计算价格使用了券码），则正常下发展示使用成功的卡bin优惠券信息
      if (nowUseCoupon) {
        return nowUseCoupon
      }
      // 非token支付情况下，选择第一个有cardName的券
      const curCoupon = usableCouponList.value?.find(
        item => item?.card?.card_name
      )
      return curCoupon
    }
  })

  const isShowPayBinCoupons = computed(() => {
    return !!supportBinData.value
  })

  return {
    isShowPayBinCoupons
  }
}
