var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"discount-tips-block"},[(_setup.isShowPayBinCoupons)?_c(_setup.PayBinCoupons,{staticClass:"discount-tips__coupons_txt",attrs:{"scene":"discount-tips","card-bin":_setup.nowShowTokenPaymentCardBin,"is-pay-bin-coupons-add":_setup.isPayBinCouponsAdd,"is-show-token-payment":_setup.isCouponsShowTokenPayment,"language":_vm.language,"pay-code":_vm.currentPayment.code}}):_vm._e(),_vm._v(" "),(_setup.isShowTokenBinDiscountBlock && _setup.isNoShowTokenBinRandomDiscount && (_setup.isRouterPay || _setup.isInstallmentPay) && !_vm.isHomogenization && !_setup.isInstallmentIsUseNewCard)?_c('div',{staticClass:"bin-discount-block"},[_c('span',{staticClass:"discount-tips-item bin-desc"},[_vm._v("\n      "+_vm._s(_setup.tokenBinDiscountDesc)+"\n    ")]),_vm._v(" "),(_setup.isShowRandomDiscountDes || _setup.isShowOnlinePayDiscount)?_c('span',{staticClass:"add-tip"},[_vm._v("\n      +\n    ")]):_vm._e()]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"discount-percent_tips discount-percent-sy"},[(
        _setup.isShowOnlinePayDiscount &&
          _setup.onlinePayDiscountInfoValue &&
          _setup.onlinePayDiscountInfoValue.discountType != 2
      )?_c('div',{staticClass:"discount-tips-item"},[(_setup.onlinePayDiscountInfoMinAmount != 0)?_c('div',{staticClass:"payment-discount-tip",style:({ color: _setup.IS_RW && '#FC4070' })},[(_setup.onlinePayDiscountInfoValue.discountType == 0)?[_vm._v("\n          "+_vm._s(_setup.template(
              _setup.onlinePayDiscountInfoValue.min.amountWithSymbol,
              _setup.onlinePayDiscountInfoValue.amount.amountWithSymbol,
              _vm.language.SHEIN_KEY_PWA_16207
            ))+"\n        ")]:_vm._e(),_vm._v(" "),(_setup.onlinePayDiscountInfoValue.discountType == 1)?[_vm._v("\n          "+_vm._s(_setup.template(
              _setup.onlinePayDiscountInfoValue.min.amountWithSymbol,
              _setup.onlinePayDiscountInfoValue.percent + '%OFF',
              _vm.language.SHEIN_KEY_PWA_16207
            ))+".\n        ")]:_vm._e(),_vm._v(" "),(
            _setup.onlinePayDiscountInfoValue.discountType == 1 &&
              _setup.onlinePayDiscountInfoValue.limit_value &&
              +_setup.onlinePayDiscountInfoValue.limit_value.amount != 0
          )?[_vm._v("\n          "+_vm._s(_vm.language.SHEIN_KEY_PWA_19681.replace(
              '{limitValue}',
              _setup.onlinePayDiscountInfoValue.limit_value.amountWithSymbol
            ))+"\n        ")]:_vm._e()],2):_c('div',{staticClass:"payment-discount-tip",style:({ color: _setup.IS_RW && '#FC4070' })},[(
            _setup.onlinePayDiscountInfoValue.discountType == 1 &&
              _setup.onlinePayDiscountInfoValue.limit_value &&
              +_setup.onlinePayDiscountInfoValue.limit_value.amount != 0
          )?[_vm._v("\n          "+_vm._s(_vm.language.SHEIN_KEY_PWA_19681.replace(
              '{limitValue}',
              _setup.onlinePayDiscountInfoValue.limit_value.amountWithSymbol
            ))+"\n        ")]:_vm._e()],2)]):_vm._e(),_vm._v(" "),(_setup.isShowRandomDiscountDes)?_c('div',{staticClass:"discount-tips-item"},[_c('div',{staticClass:"payment-discount-tip",style:({ color: _setup.IS_RW && '#FC4070' })},[_vm._v("\n        "+_vm._s(_setup.randomDiscountDes)+"\n      ")])]):_vm._e(),_vm._v(" "),(!_vm.isShowTokenPayment && !_setup.isShowTokenFrontInstallment)?[(
          _setup.isShowOnlinePayDiscount &&
            _setup.isShowCardBinDiscount &&
            _setup.cardBinDiscountDesc
        )?_c('span',[_vm._v("\n        +\n      ")]):_vm._e(),_vm._v(" "),(_setup.isShowCardBinDiscountDesc)?_c('span',{staticClass:"discount-tips-item"},[_c('span',{ref:"cardBinDiscountDescRef"},[_vm._v(_vm._s(_setup.cardBinDiscountDesc))]),_vm._v(" "),_c('i',{ref:"cardBinDiscountTipRef",staticClass:"sui_icon_doubt_16px_2 icon",class:[
            _setup.paymentIconPrefix,
            {
              'tip-wrapped': _setup.cardBinDiscountTipRefIsWrapped,
              'no-left': _setup.isTextWrapped
            }
          ],staticStyle:{"width":"12px"},on:{"click":function($event){$event.stopPropagation();return _setup.handlePaymentOptionsOper('bin-discount-article')}}})]):_vm._e()]:_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }